import React, { Component } from 'react';
import { Input, Switch, Button, Link, RangeSlider, Steps } from 'optimizely-oui';
import { events } from '../constants/analytics';
import { getText } from '../constants/copy';

export default class Congrats extends Component {

  onSeeDocsClick() {
    window.analytics.track(events.SEE_DOCS);
  }

  onCreateAccountClick() {
    window.analytics.track(events.CREATE_ACCOUNT);
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const searchParams = params.toString() || ''
    window.top.location = 'https://www.optimizely.com/rollouts/?modal=rollouts-signup-new&' + searchParams
  }

  render() {
    return (
      <div style={styles.container}>
        <div style={styles.congrats}>
          <div style={styles.icon}>
            🎉
          </div>
          <div style={styles.finalButtons}>
            <div className="push--right">
              <div onClick={this.onSeeDocsClick.bind(this)}>
                <Link
                  href={'https://docs.developers.optimizely.com/rollouts/docs/'}
                  newWindow={true}
                >
                  <Button
                    isLink={true}>
                      { getText('see-docs') }
                  </Button>
                </Link>
              </div>
            </div>
            <div>
              { this.props.tooltip }
              <div onClick={this.onCreateAccountClick.bind(this)}>
                <Link
                  href={'javascript:void(0);'}
                  newWindow={false}
                  style={"reverse"}
                >
                  <Button
                    style="highlight"
                    isLink={true}
                  >
                    { getText('create-account') }
                  </Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="push-double--top">
            <Button
              onClick={this.props.resetInteractive}
              style='plain'
            >
              { getText('reset-demo') }
            </Button>
          </div>
        </div>
      </div>
    )
  }
}
const styles = {
  icon: {
    fontSize: '80px',
  },
  container: {
    marginLeft: '20px',
  },
  congrats: {
    width:'1032px',
    height: '280px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  finalButtons: {
    display: 'flex'
  }
}
