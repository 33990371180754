export const datafileBuilder = function(input) {

  let options = {
    experiment: {
      key: input.experimentKey,
      traffic: [
        input.trafficA,
        input.trafficB,
      ],
      values: [
        input.valueA,
        input.valueB,
      ],
    },
    feature: {
      key: input.featureKey,
      enabled: input.featureEnabled,
      rollout: input.rolloutPercentage,
    }
  }

  if (options.experiment.key) {
    return (
`{
  "experiments": {
    "${options.experiment.key}": [
      {
        "variation": "A",
        "traffic": "${options.experiment.traffic[0]}",
        "${options.feature.key}": {
          "amount": "${options.experiment.values[0]}"
        }
      },
      {
        "variation": "B",
        "traffic": "${options.experiment.traffic[1]}",
        "${options.feature.key}": {
          "amount": "${options.experiment.values[1]}"
        }
      }
    ]
  }
}`  )
  }

  if (!options.feature.key) {
    return (
`{
  "featureFlags": {}
}`  )
  }

  if (options.feature.enabled === null || options.feature.enabled === undefined) {
    return (
`{
  "featureFlags": {
    "${options.feature.key}": {}
  }
}`  )
  }

    /*
  if (!options.feature.rollout) {
    return (
`{
  "featureFlags": {
    "${options.feature.key}": {
      "enabled": ${options.feature.enabled}
    }
  }
}`
    )
  }*/

  return (
`{
  "featureFlags": {
    "${options.feature.key}": {
      "enabled": ${options.feature.enabled},
      "rollout": "${options.feature.rollout}%"
    }
  }
}`  )
}
