import React, { Component } from 'react';
import { RangeSlider } from 'optimizely-oui';

export default class RolloutComponent extends Component {
  render() {
    return (
      <RangeSlider
        style={{width: '200px'}}
        onChange={this.props.onChange}
        value={this.props.value}
      />
    )
  }
}
