import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Home from './pages/home';
import DatafileVisualizer from './pages/datafile_visualizer';
import Onboarding from './pages/onboarding';
import Toggle from './pages/toggle';
import './App.css';

// Put the sdk on the page for fun
import optimizely from '@optimizely/optimizely-sdk'
window.optimizely = optimizely

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Router>
        <div className="flex flex--dead-center height--1-1">
          <Route path="/" exact component={Home} />
          <Route path="/datafile" component={DatafileVisualizer} />
          <Route path="/onboarding" component={Onboarding} />
          <Route path="/toggle" component={Toggle} />
        </div>
      </Router>
    );
  }
}

const styles = {
}

export default App;
