import React, { Component } from 'react';

export default class Tooltip extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const offsetX = this.props.offsetX || '0px';
    const offsetY = this.props.offsetY || '0px';
    const direction = this.props.direction
    const className = direction === 'up' || direction === 'down'
      ? "fader bouncer"
      : "fader jiggler"

    return (
      <div style={{ position:'fixed', zIndex: '10', }}>
        <div className={className} style={{position:'absolute', left: offsetX, top: offsetY }}>
          { this.props.direction === 'left' && (<div className="arrow-left" style={styles.left}></div>) }
          { this.props.direction === 'right' && (<div className="arrow-right" style={styles.right}></div>) }
          { this.props.direction === 'up' && (<div className="arrow-up" style={styles.up}></div>) }
          { this.props.direction === 'down' && (<div className="arrow-down" style={styles.down}></div>) }
          <div style={styles.tooltip}>
            <div>{ this.props.text }</div>
          </div>
        </div>
      </div>
    )
  }
}

const styles = {
  left: {
    position: 'absolute',
    marginTop: '10px',
    marginLeft: '-7px',
  },
  right: {
    position: 'absolute',
    marginTop: '10px',
    left: '99%',
  },
  up: {
    top: '-6px',
    left: '80%',
    position: 'absolute',
  },
  down: {
    top: '99%',
    left: '80%',
    position: 'absolute',
  },
  tooltip: {
    boxShadow: '0px 2px 5px rgba(0,0,0,0.15)',
    height: '35px',
    border: '1px solid #0037FF',
    backgroundColor: '#FFFFFF',
    textColor: '#0037FF',
    whiteSpace: 'nowrap',
    padding: '5px 10px 5px 10px',
    borderRadius: '5px',
    color: '#0037FF',
  }
}
