export const events = {
  FEATURE_NAMED: 'Rollouts Demo Feature Named',
  ROLLOUT_TOGGLED: 'Rollouts Demo Rollout Toggled',
  SLIDER_CLICKED: 'Rollouts Demo Rollout Slider Clicked',
  FEATURE_SAVED: 'Rollouts Demo Feature Saved',
  STEP1_CREATE_VIEWED: 'Rollouts Demo Step 1 Create a Feature Viewed',
  STEP2_IMPLEMENT_VIEWED: 'Rollouts Demo Step 2 Implement Your Feature Viewed',
  STEP3_RUN_VIEWED: 'Rollouts Demo Step 3 Run Your App Viewed',
  STEP4_CONGRATS_VIEWED: 'Rollouts Demo Step 4 Congrats Viewed',
  APP_CODE_RERUN: 'Rollouts Demo App Code Rerun',
  SEE_DOCS: 'Rollouts Demo See Docs Clicked',
  CREATE_ACCOUNT: 'Rollouts Demo Create Account Clicked',
  DEMO_RESET: 'Rollouts Demo Demo Reset',
}
