import React, { Component } from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');


export default class DatafileComponent extends Component {
  render() {
    return (
      <div className="flex flex--column" style={styles.container}>
        <div className="soft" style={styles.datafileHeader}>
          <span>https://cdn.optimizely.com/datafiles/<strong>sdkKey</strong>.json</span>
        </div>
        <CodeMirror
          options={{
            mode: 'json',
            theme: 'material',
            lineNumbers: true
          }}
          value={this.props.code}
        />
    </div>
    )
  }
}

const styles = {
  container: {
    width:'400px',
    height: '280px',
    overflowY: 'hidden'
  },
  datafileHeader: {
    font: '12px Monospace',
    backgroundColor: '#FFBF46',
    borderRadius: '5px 5px 0px 0px'
  }
}
