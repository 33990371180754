import optimizely from '@optimizely/optimizely-sdk'
import React, { Component } from 'react';
import { Input, Switch, Button, Link, RangeSlider, Steps } from 'optimizely-oui';
import { DragSource, DragDropContextProvider, DropTarget } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import VerticalSteps from '../components/vertical_steps';
import DatafileComponent from '../components/datafile';
import DraggableDatafile from '../components/draggable_datafile';
import DroppableDatafile from '../components/droppable_datafile';
import PropogateComponent from '../components/propagate';
import DownloadComponent from '../components/download';
import RolloutComponent from '../components/rollout';
import StepInstruction from '../components/step_instruction';
import Tooltip from '../components/tooltip';
import YourAppCode from '../components/your_app_code';
import UserExperiences from '../components/user_experiences';
import Congrats from '../components/congrats';
import { datafileBuilder } from '../helpers/datafile_builder';
import { getText, getOffsetX, getOffsetY } from '../constants/copy';
import INSTRUCTIONS from '../constants/instructions';
import { events } from '../constants/analytics';

window.optimizely = optimizely

class Onboarding extends Component {
  constructor(props) {
    super(props)
    this.state = this.getInitialState();
  }

  resetInteractive() {
    this.setState(this.getInitialState());
    window.analytics.track(events.DEMO_RESET);
  }

  getInitialState() {
    return {
      // Local State
      experimentKey: null,
      trafficA: null,
      trafficB: null,
      valueA: null,
      valueB: null,
      featureKey: '',
      featureEnabled: null,
      rolloutPercentage: 50,
      // CDN State
      cdn: {
        experimentKey: null,
        trafficA: null,
        trafficB: null,
        valueA: null,
        valueB: null,
        featureKey: null,
        featureEnabled: null,
        rolloutPercentage: 50,
      },
      propagations: [],
      hasMovedSlider: false,
      step: 0,
      showTooltips: true,
      tooltips: [
        { id: 'name',   isDone: false },
        { id: 'toggle', isDone: false },
        { id: 'rollout',isDone: false },
        { id: 'save',   isDone: false },
        { id: 'next1',  isDone: false },
        //{ id: 'sync',   isDone: false },
        { id: 'next2',  isDone: false },
        //{ id: 'run',    isDone: false },
        { id: 'next3',  isDone: false },
        { id: 'congrats',  isDone: false },
      ],
      datafileDownloads: [],
      datafileDownloaded: false,
      highlightDatafile: false,
      ranApp: false,
      appExperience: {},
    }
  }

  componentDidMount() {
    window.analytics.track(events.STEP1_CREATE_VIEWED);
  }

  startPropagation(changes) {
    let propagateContent = (
      <div style={styles.ball}>
      </div>
    );

    let propagationComp = (
      <PropogateComponent
        width={195}
        key={Math.random()}
        onDone={this.finishPropagation.bind(this, changes)}
        component={propagateContent}
      />
    )

    this.setState({
      propagations: [
        ...this.state.propagations,
        propagationComp
      ]
    })
  }

  finishPropagation(changes) {
    let propagations = [...this.state.propagations]
    let cdn = { ...this.state.cdn, ...changes }
    propagations.shift();
    this.setState({
      cdn,
      propagations,
    })
  }

  onChangeKey(event) {
    let featureKey = event.target.value
    let change = { featureKey }
    this.setState(change)
    this.completeTooltip('name');
  }

  onFeatureNamed(event) {
    let featureKey = event.target.value
    console.log('Feature named');
    window.analytics.track(events.FEATURE_NAMED, {
      featureKey: featureKey,
    });
  }

  completeTooltip(id) {
    let tooltips = this.state.tooltips.map((tooltip) => {
      if (id !== tooltip.id) {
        return tooltip;
      } else {
        tooltip.isDone = true;
        return tooltip;
      }
    })
    this.setState({ tooltips });
  }

  onRollout(event) {
    let rolloutPercentage = Number(event.target.value)
    this.setState({ rolloutPercentage, hasMovedSlider: true, })
    this.completeTooltip('rollout');
  }

  onSwitch(event) {
    let featureEnabled = !!event.target.checked
    let change = { featureEnabled }
    console.log('Slider toggled');
    window.analytics.track(events.ROLLOUT_TOGGLED, {
      toggle: featureEnabled,
    });
    this.setState(change)
    this.completeTooltip('toggle')
  }

  downloadDatafile() {
    let datafileDownloaded = true;
    let change = { datafileDownloaded: true }
    this.startDownload(change)
    this.completeTooltip('sync')
  }

  runApp() {
    this.appExperience.startRun();
  }

  startDownload(changes) {
    let propagateContent = (
      <div style={styles.ball}>
      </div>
    );

    let propagationComp = (
      <PropogateComponent
        width={140}
        key={Math.random()}
        onDone={this.finishDownload.bind(this, changes)}
        component={propagateContent}
      />
    )

    this.setState({
      datafileDownloads: [
        ...this.state.datafileDownloads,
        propagationComp
      ]
    })
  }

  finishDownload(changes) {
    let datafileDownloads = [...this.state.datafileDownloads]
    datafileDownloads.shift();
    this.setState({
      datafileDownloaded: true,
      highlightDatafile: true,
      datafileDownloads,
    })

    setTimeout(() => {
      this.setState({
        highlightDatafile: false,
      })
    }, 300)
  }

  swtichStage(stage) {
    let change = { stage }
    this.setState(change)
  }

  onPrevious() {
    if (this.state.step > 0) {
      let step = this.state.step - 1
      this.setState({ step })
      this.trackPageView(step);
    }
    if (this.state.step < 2) {
      //this.setState({ datafileDownloaded: false })
    }
  }

  onNext() {
    if (this.state.step < INSTRUCTIONS.length - 1) {
      let step = this.state.step + 1
      if (step == 2) {
        this.runApp();
      }
      this.setState({ step })
      this.completeTooltip('next' + step);
      this.trackPageView(step);
    }
  }

  trackPageView(step) {
    const stepName = [
      events.STEP1_CREATE_VIEWED,
      events.STEP2_IMPLEMENT_VIEWED,
      events.STEP3_RUN_VIEWED,
      events.STEP4_CONGRATS_VIEWED,
    ]

    const eventName = stepName[step];
    window.analytics.track(eventName)
  }

  onRunApp() {
    let ranApp = true;
    this.setState({ ranApp })
  }

  onStartRun() {
    //this.completeTooltip('run');
  }

  isNextDisabled() {
    let isDisabled = false;
    switch(this.state.step) {
      case 0:
        isDisabled = !this.state.cdn.featureKey
        break;
      case 1:
        //isDisabled = !this.state.datafileDownloaded
        isDisabled = false;
        break;
      case 2:
        isDisabled = !this.state.ranApp
        break;
      case 3:
        isDisabled = true
        break;
    }
    return isDisabled
  }

  onSave() {
    let newState = {
      experimentKey: this.state.experimentKey,
      trafficA: this.state.trafficA,
      trafficB: this.state.trafficB,
      valueA: this.state.valueA,
      valueB: this.state.valueB,
      featureKey: this.state.featureKey,
      featureEnabled: this.state.featureEnabled,
      rolloutPercentage: this.state.rolloutPercentage,
    }
    this.startPropagation(newState)
    this.completeTooltip('save')
    console.log('Feature named');
    window.analytics.track(events.FEATURE_SAVED, {
      featureKey: this.state.featureKey,
      featureEnabled: this.state.featureEnabled,
      rolloutPercentage: this.state.rolloutPercentage,
    });
  }

  onRangeSliderClick() {
    window.analytics.track(events.SLIDER_CLICKED, {
      percentage: this.state.rolloutPercentage,
    });
  }

  render() {
    let showTooltips = this.state.showTooltips
    let visibleTooltip = this.state.tooltips.find((tooltip) => (!tooltip.isDone)) || {}
    let datafile = datafileBuilder(this.state.cdn)
    let propagations = this.state.propagations

    let downloadPathVisibiltiy = {
      opacity: this.state.step === 1 ? 1.0: 0.0,
    }
    let datafileDownloads = (
      <div className="" style={{marginTop: '90px'}}>
        <div className="fade" style={{...styles.downloadPath, ...downloadPathVisibiltiy}}>
          { this.state.datafileDownloads }
        </div>
      </div>
    )

    let backButtonVisbility = {
      display: this.state.step > 0 ? 'block' : 'none',
    }

    let datafileSource = {
      beginDrag(props) {
        return {};
      }
    }

    let datafileButtonVisibility = this.state.step > 0
      ? {}
      : { display: 'none' }

    let datafileVisualizer = (
      <div className="flex">
        <div style={styles.card}>
          <span style={styles.title}>{ getText('optly-application') }</span>
          <div className="OptimizelyFrame">
            <div className="OptimizelyFrameBackground">
              <svg version="1.1" id="browser" x="0px" y="0px" viewBox="0 0 468.5 309.8">
                <g>
                  <path fill="#F0F0F0" d="M5.7,0.5h457.2c2.8,0,5.2,2.3,5.2,5.2v298.4c0,2.8-2.3,5.2-5.2,5.2H5.7c-2.8,0-5.2-2.3-5.2-5.2V5.7
                    C0.5,2.8,2.8,0.5,5.7,0.5z"/>
                  <rect x="2.1" y="34.9" fill="#FFFFFF" stroke="#CECDCD" width="464.4" height="261.5"/>
                  <circle fill="#EB1E2E" cx="35.3" cy="18.2" r="3"/>
                  <circle fill="#FAD232" cx="44.3" cy="18.2" r="3"/>
                  <circle fill="#89C549" cx="53.3" cy="18.2" r="3"/>
                  <path fill="#FFFFFF" d="M379.1,24.2H87.6c-1.5,0-2.8-1.2-2.8-2.8V15c0-1.5,1.2-2.8,2.8-2.8h291.5c1.5,0,2.8,1.2,2.8,2.8v6.5
                    C381.9,22.9,380.6,24.2,379.1,24.2z"/>
                  <g>
                    <line stroke="#DBDDDD" x1="425.9" y1="14.1" x2="425.9" y2="22.3"/>
                    <line stroke="#DBDDDD" x1="421.7" y1="18.2" x2="430" y2="18.2"/>
                  </g>
                </g>
              </svg>
            </div>
            <div className="OptimizelyFrameContent flex flex--column">
              <div className="flex flex-1 flex--row soft" style={{}}>
                { visibleTooltip.id === 'name' && showTooltips && (<Tooltip offsetX={getOffsetX('name-your-feature')} offsetY={getOffsetY('name-your-feature')} text={ getText('name-your-feature') } direction={'left'}/>) }
                <Input
                  type="text"
                  maxLength={20}
                  onChange={this.onChangeKey.bind(this)}
                  onBlur={this.onFeatureNamed.bind(this)}
                  value={this.state.featureKey}
                  placeholder={'chat_window'}
                  label={"Feature Key"}
                />
              </div>
              <div className="flex flex-1 flex--row soft flex-align--center">
                <Switch
                  isDisabled={!this.state.featureKey}
                  checked={!!this.state.featureEnabled}
                  onClick={this.onSwitch.bind(this)}
                />
                { visibleTooltip.id === 'toggle' && showTooltips && (<Tooltip offsetX={getOffsetX('turn-on-your-feature')} offsetY={getOffsetY('turn-on-your-feature')} text={ getText('turn-on-your-feature') } direction={'left'}/>) }
                <div className="width--1-1">
                  <div onClick={this.onRangeSliderClick.bind(this)}>
                    <RangeSlider
                      style={{width: '200px'}}
                      onChange={this.onRollout.bind(this)}
                      value={this.state.rolloutPercentage}
                      isDisabled={!this.state.featureEnabled}
                    />
                  </div>
                    { visibleTooltip.id === 'rollout' && showTooltips && (<Tooltip offsetX={getOffsetX('rollout-your-feature')} offsetY={getOffsetY('rollout-your-feature')} text={ getText('rollout-your-feature') } direction={'left'}/>) }
                </div>
              </div>
              <div className="flex flex-justified--end soft--sides">
                { visibleTooltip.id === 'save' && showTooltips && (<Tooltip offsetX={getOffsetX('save-your-changes')} offsetY={getOffsetY('save-your-changes')} text={ getText('save-your-changes') } direction={'left'}/>) }
                <Button
                  onClick={this.onSave.bind(this)}
                  isDisabled={!this.state.featureKey || !this.state.hasMovedSlider}
                  style="highlight"
                >
                  { getText('save') }
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="width--200 flex flex-align--center" style={{paddingTop: '30px'}}>
          <div style={styles.uploadPath}>
            { propagations }
          </div>
        </div>
        <div style={styles.card}>
          <div style={styles.title}>{ getText('your-datafile') }</div>
          <DatafileComponent
            code={datafile}
          />
        </div>
      </div>
    )

    let datafileDropVisibility = {
      display: this.state.datafileDownloaded ? 'none' : 'block',
    }

    let datafileDownload = (
      <div style={styles.datafileSyncContainer}>
        { visibleTooltip.id === 'sync' && showTooltips && (<Tooltip offsetX={getOffsetX('sync-your-feature-configuration')} offsetY={getOffsetY('sync-your-feature-configuration')} text={ getText('sync-your-feature-configuration') } direction={'left'}/>) }
        <Button
          style={this.state.step === 1 ? 'highlight' : null}
          onClick={this.downloadDatafile.bind(this)}
        >
          <div style={styles.downloadButtonText}>
            <div>Sync Datafile</div>
            <svg style={{'paddingLeft': '10px'}} width='30px' className="svg-inline--fa fa-cloud-download-alt fa-w-20" viewBox="0 0 640 512">
              <path fill="currentColor" d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z">
              </path>
            </svg>
          </div>
        </Button>
      </div>
    )

    let datafileHighlighter = this.state.highlightDatafile ? (
      <div style={styles.datafileHighlightContainer}>
        <span style={styles.datafileHighlight} className="pulsater">datafile</span>
      </div>
    ) : null;

    let appCodeVisualizer = (
      <div style={{width: '1030px', marginLeft: '147px'}}>
        <div style={styles.card}>
          { datafileHighlighter }
          <span style={styles.title}> { getText('your-app-code') }</span>
          <YourAppCode
            featureKey={this.state.featureKey}
            datafileDownloaded={this.state.datafileDownloaded}
            />
        </div>
      </div>
    )

    let userExperienceVisualizer = (
      <div style={{marginLeft: '20px'}}>
        <div style={styles.card}>
          <span style={styles.title}> { getText('your-apps-users') }</span>
          <UserExperiences
            onRef={ref => (this.appExperience = ref)}
            rollout={this.state.rolloutPercentage}
            featureEnabled={this.state.featureEnabled}
            featureKey={this.state.featureKey}
            onRunApp={this.onRunApp.bind(this)}
            onStartRun={this.onStartRun.bind(this)}
          />
        </div>
      </div>
    )

    const left = INSTRUCTIONS[this.state.step].left;
    const top = INSTRUCTIONS[this.state.step].top;

    return (
      <DragDropContextProvider backend={HTML5Backend}>
        <div className="App">
          <div className="flex" style={styles.stepsContainer}>
            <div>
              {
                StepInstruction(
                  INSTRUCTIONS[this.state.step].title,
                  this.state.step + 1,
                  INSTRUCTIONS[this.state.step].text
                )
              }
            </div>
            <div className="slide flex" style={{...styles.contentContainer, left, top}}>
              { datafileVisualizer }
              { datafileDownloads }
              { appCodeVisualizer  }
              { userExperienceVisualizer }
              { <Congrats resetInteractive={this.resetInteractive.bind(this)} 
                  tooltip={ visibleTooltip.id === 'congrats' && showTooltips && (<Tooltip offsetX={getOffsetX('try-it-out')} offsetY={getOffsetY('try-it-out')} text={ getText('try-it-out') } direction={'left'}/>) }
                />}
            </div>
          </div>
          <div className="flex" style={styles.buttons}>
            <div style={{...styles.backButton, ...backButtonVisbility}}>
              <Button
                onClick={this.onPrevious.bind(this)}
              >
                { getText('back') }
              </Button>
            </div>
            <div style={styles.nextButton}>
              { visibleTooltip.id === 'next1' && showTooltips && (<Tooltip offsetX={getOffsetX('ready-to-implement')} offsetY={getOffsetY('ready-to-implement')} text={ getText('ready-to-implement') } direction={'down'}/>) }
              { visibleTooltip.id === 'next2' && showTooltips && (<Tooltip offsetX={getOffsetX('run-the-code')} offsetY={getOffsetY('run-the-code')} text={ getText('run-the-code') } direction={'down'}/>) }
              { visibleTooltip.id === 'next3' && showTooltips && (<Tooltip offsetX={getOffsetX('see-whats-next')} offsetY={getOffsetY('see-whats-next')} text={ getText('see-whats-next') } direction={'down'}/>) }
              <Button
                onClick={this.onNext.bind(this)}
                style="highlight"
                isDisabled={this.isNextDisabled.bind(this)()}
              >
                { getText('next') }
              </Button>
            </div>
          </div>
        </div>
      </DragDropContextProvider>
    );
  }
}

const styles = {
  contentContainer: {
    position: 'relative'
  },
  buttons: {
    justifyContent: 'flex-end',
    marginTop: '10px',
  },
  nextButton: {
    paddingLeft: '5px'
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    padding: '10px',
    fontFamily: "Verdana,sans-serif",
    fontWeight: '500',
  },
  uploadPath: {
    height: '1px',
    width: '100%',
    backgroundColor: '#263238'
  },
  downloadPath: {
    position:'fixed',
    backgroundColor: '#263238',
    width: '148px',
    height: '1px',
  },
  ball: {
    width: '20px',
    height: '20px',
    marginTop: '-10px',
    borderRadius: '10px',
    backgroundColor: '#FFBF46',
    //border: '1px solid #263238'
  },
  stepsContainer: {
    flexDirection: 'column',
    width: '1032px',
    overflowX: 'hidden',
    overflowY: 'hidden',
  },
  datafileSyncContainer: {
    position: 'absolute',
    zIndex: 5,
    top: '265px',
  },
  runAppContainer: {
    position: 'absolute',
    zIndex: 5,
    top: '265px',
  },
  downloadButtonText: {
    display: 'flex',
    alignItems: 'center',
  },
  datafileHighlightContainer: {
    position: 'absolute',
    zIndex: 5,
    top: '50px',
  },
  datafileHighlight: {
    position: 'absolute',
    left: '140px',
    top: '31px',
    fontFamily: 'monospace',
    fontSize: '12px',
  }
}

export default Onboarding;
