import React from 'react';

export default function StepInstruction(title, number, instruction) {
  return (
    <div style={styles.stepContainer}>
      <div style={styles.titleContainer}>
        <div style={styles.number} className="flex--dead-center">{number}</div>
        <h2 style={styles.title}>{title}</h2>
      </div>
      <div style={styles.subTitleContainer}>
        <span style={styles.subtitle}>{instruction}</span>
      </div>
    </div>
  )
}

const styles = {
  subtitle: {
    marginLeft: '35px',
  },
  title: {
    marginBottom: '0px',
    lineHeight: 'unset',
  },
  subTitleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  stepContainer: {
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'column',
  },
  number: {
    backgroundColor: '#514FFF',
    borderRadius: '20px',
    color: 'white',
    width: '25px',
    height: '25px',
    display: 'flex',
    marginRight: '10px',
    fontWeight: '800',
  }
}
