import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import DatafileVisualizer from './datafile_visualizer';
import Onboarding from './onboarding';
import Toggle from './toggle';

class Home extends Component {

  render() {
    return (
      <ul>
        <li> <Link to="/datafile">Datafile</Link> </li>
        <li> <Link to="/onboarding">Onboarding</Link> </li>
        <li> <Link to="/toggle">Toggle</Link> </li>
      </ul>
    );
  }
}

const styles = {
}

export default Home;
