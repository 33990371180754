import React from 'react';
import { DragSource } from 'react-dnd';
import { Button } from 'optimizely-oui';

const datafileSource = {
  beginDrag(props) {
    return {};
  }
};

function collect(connect, monitor) {
  return {
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }
}

function DraggableDatafile({ connectDragSource, isDragging }) {
  return connectDragSource(
    <div style={{
      opacity: isDragging ? 0.5 : 1,
    }}>
      <Button style="tiny">
        {`{ datafile }`}
      </Button>
    </div>
  );
}

export default DragSource('datafile', datafileSource, collect)(DraggableDatafile);
