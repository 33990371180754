import React, { Component } from 'react';
import {Controlled as CodeMirror} from 'react-codemirror2';

require('codemirror/lib/codemirror.css');
require('codemirror/theme/material.css');
require('codemirror/theme/neat.css');
require('codemirror/mode/xml/xml.js');
require('codemirror/mode/javascript/javascript.js');


export default class YourAppCode extends Component {
  render() {
    const featureKey = this.props.featureKey || '<FEATURE_KEY>'
    const userId = this.props.userId || '<USER_ID>'
    const datafileText = this.props.datafileDownloaded
      ? 'datafile'
      : 'datafile'

    const appCode = (
`// Using the datafile, create an instance of Optimizely
var optly = optimizely.createInstance({ datafile: ${datafileText} });

// Use the optimizely instance to see if a feature is enabled
var enabled = optly.isFeatureEnabled('${featureKey}', userId);

if (enabled) {
  // Your feature is enabled. Show your feature
  console.log(\`\${userId} got the ${featureKey} feature\`)
} else {
  // Your feature is not enabled. Hide your feature
  console.log(\`\${userId} did not get the ${featureKey}\ feature\`)
}`)

    return (
      <div style={styles.container}>
        <div style={{position: 'absolute'}}>
          <div style={ styles.codeHeader }></div>
          <CodeMirror
            options={{
              mode: 'javascript',
              theme: 'material',
              lineNumbers: true
            }}
            value={appCode}
          />
        </div>
      </div>
    )
  }
}

const styles = {
  container: {
    flexDirection: 'column',
    width:'505px',
    height: '280px',
    overflowY: 'hidden'
  },
  codeHeader: {
    font: '12px Monospace',
    //backgroundColor: '#009FB7',
    backgroundColor: '#233238',
    color: '#FFFFFF',
    borderRadius: '5px 5px 0px 0px',
    //padding: '10px 0px 10px 0px',
    height: '14px',
    width: '100%',
  },
  download: {
    backgroundColor: '#233238',
    position: 'absolute',
    right: '50px',
    marginTop: '36px',
    zIndex: '100',
  },
  initialize: {
    padding: '5px',
  }
}
