import React, { Component } from 'react';
import { Input, Switch, Button, RangeSlider } from 'optimizely-oui';
import DatafileComponent from '../components/datafile';
import PropogateComponent from '../components/propagate';
import RolloutComponent from '../components/rollout';
import { datafileBuilder } from '../helpers/datafile_builder';

class DatafileVisualizer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      experimentKey: null,
      trafficA: null,
      trafficB: null,
      valueA: null,
      valueB: null,
      featureKey: null,
      featureEnabled: null,
      rolloutPercentage: 100,
      cdn: {
        experimentKey: null,
        trafficA: null,
        trafficB: null,
        valueA: null,
        valueB: null,
        featureKey: null,
        featureEnabled: null,
        rolloutPercentage: 100,
      },
      propagations: []
    }
  }

  startPropagation(changes) {
    let propagateContent = (
      <div style={styles.ball}>
      </div>
    );

    let propagationComp = (
      <PropogateComponent
        key={Math.random()}
        onDone={this.finishPropagation.bind(this, changes)}
        component={propagateContent}
      />
    )

    this.setState({
      propagations: [
        ...this.state.propagations,
        propagationComp
      ]
    })
  }

  finishPropagation(changes) {
    let propagations = [...this.state.propagations]
    let cdn = { ...this.state.cdn, ...changes }
    propagations.shift();
    this.setState({
      cdn,
      propagations,
    })
  }

  onChangeKey(event) {
    let featureKey = event.target.value
    let change = { featureKey }
    this.setState(change)
  }

  onRollout(event) {
    let rolloutPercentage = Number(event.target.value)
    let change = { rolloutPercentage }
    this.setState(change)
  }

  onSwitch(event) {
    let featureEnabled = !!event.target.checked
    let change = { featureEnabled }
    this.setState(change)
  }

  onSave() {
    let newState = {
      experimentKey: this.state.experimentKey,
      trafficA: this.state.trafficA,
      trafficB: this.state.trafficB,
      valueA: this.state.valueA,
      valueB: this.state.valueB,
      featureKey: this.state.featureKey,
      featureEnabled: this.state.featureEnabled,
      rolloutPercentage: this.state.rolloutPercentage,
    }
    this.startPropagation(newState)
  }

  render() {
    let datafile = datafileBuilder(this.state.cdn)
    let propagations = this.state.propagations

    return (
      <div className="App">
        <div className="flex">
          <div style={styles.card}>
            <span style={styles.title}>Optimizely Application</span>
            <div className="OptimizelyFrame flex flex--column">
              <div className="flex flex-1 flex--row soft">
                <Input
                  type="text"
                  onChange={this.onChangeKey.bind(this)}
                  defaultValue={this.state.featureKey}
                  label={"Feature Key"}
                />
              </div>
              <div className="flex flex-1 flex--row soft flex-align--center">
                <Switch
                  isDisabled={!this.state.featureKey}
                  onClick={this.onSwitch.bind(this)}
                />
                <div className="width--1-1">
                  <RangeSlider
                    style={{width: '200px'}}
                    onChange={this.onRollout.bind(this)}
                    value={this.state.rolloutPercentage}
                    isDisabled={!this.state.featureEnabled}
                  />
                </div>
              </div>
              <div className="flex flex-justified--end soft--sides">
                <Button
                  onClick={this.onSave.bind(this)}
                  style="highlight"
                >
                  Save
                </Button>
              </div>
            </div>
          </div>
          <div className="width--200 flex flex-align--center" style={{paddingTop: '30px'}}>
            <div style={styles.uploadPath}>
              { propagations }
            </div>
          </div>
          <div style={styles.card}>
            <span style={styles.title}>Example Datafile</span>
            <DatafileComponent
              code={datafile}
            />
          </div>
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    padding: '10px',
    fontFamily: "Verdana,sans-serif",
    fontWeight: '500',
  },
  uploadPath: {
    height: '1px',
    width: '100%',
    backgroundColor: '#263238'
  },
  ball: {
    width: '20px',
    height: '20px',
    marginTop: '-10px',
    borderRadius: '10px',
    backgroundColor: '#FFBF46',
    //border: '1px solid #263238'
  }
}

export default DatafileVisualizer;
