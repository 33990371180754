import React, { Component } from 'react';
import { Input, Switch, Button, RangeSlider } from 'optimizely-oui';
import RolloutComponent from '../components/rollout';
import { datafileBuilder } from '../helpers/datafile_builder';

class Toggle extends Component {

  onSwitch(event) {
    let featureEnabled = !!event.target.checked
    let change = { featureEnabled }
    this.setState(change)
  }

  render() {
    return (
      <div className="App">
        <div className="flex">
          <Switch
            onClick={this.onSwitch.bind(this)}
          />
        </div>
      </div>
    );
  }
}

const styles = {
  card: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  title: {
    padding: '10px',
    fontFamily: "Verdana,sans-serif",
    fontWeight: '500',
  },
  uploadPath: {
    height: '1px',
    width: '100%',
    backgroundColor: '#263238'
  },
  ball: {
    width: '20px',
    height: '20px',
    marginTop: '-10px',
    borderRadius: '10px',
    backgroundColor: '#FFBF46',
    //border: '1px solid #263238'
  }
}

export default Toggle;
