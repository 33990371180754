import React, { Component } from 'react';

export default class Propagate extends Component {
  constructor(props) {
    super(props)
    this.state = {
      display: 'block',
    }
  }

  componentDidMount() {
    setTimeout(() => {
      let display = 'none';
      this.setState({ display })
      this.props.onDone();
    }, 490);
  }

  render() {
    return (
      <div style={{ position:'fixed', width: `${this.props.width}px`}}>
        <div className="mover" style={{position:'absolute'}}>
          {this.props.component}
        </div>
      </div>
    )
  }
}
