import React, { Component } from 'react';

export default function LightBulb(isOn) {
  return (
    <svg version="1.1" x="0px" y="0px"
       width="10px" height="20px" viewBox="0 0 287.87 504.67" enableBackground="new 0 0 287.87 504.67">
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M92.78,441.104c0,0,36.4,33.058,36.4,44.222"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M194.268,441.104c0,0-36.4,33.058-36.4,44.222"
      />
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,369.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,350.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,369.05z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,387.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,368.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,387.05z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,405.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,386.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,405.05z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,423.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,404.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,423.05z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,441.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,422.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,441.05z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M202.488,459.05
      c-0.311,1.619-1.587,2.735-2.852,2.493L87.922,440.175c-1.266-0.241-2.041-1.75-1.73-3.37l2.206-11.53
      c0.31-1.619,1.586-2.735,2.852-2.492l111.715,21.367c1.263,0.242,2.038,1.751,1.728,3.37L202.488,459.05z"/>
    <path fill={isOn ? "#E4D91B" : "#FFFFFF"} stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M282.87,143.93c0,38.37-15.55,73.1-40.689,98.24
      c-40.8,40.801-45.021,76.38-44.92,89.851H90.61c0.1-13.471-4.12-49.05-44.92-89.851C20.55,217.03,5,182.3,5,143.93
      c0-38.36,15.55-73.1,40.69-98.24C70.83,20.55,105.57,5,143.93,5c38.37,0,73.1,15.55,98.25,40.69
      C267.32,70.83,282.87,105.57,282.87,143.93z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M90.61,332.021c-0.01,3.72-0.36,5.76-0.36,5.76
      l-2.68-5.76H90.61z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M200.3,332.021l-2.681,5.76
      c0,0-0.35-2.04-0.359-5.76H200.3z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M211.56,336.45v17.42c0,2.45-1.2,4.43-2.68,4.43
      H78.17c-1.48,0-2.68-1.979-2.68-4.43v-17.42c0-2.45,1.2-4.43,2.68-4.43h130.71C210.36,332.021,211.56,334,211.56,336.45z"/>
    <path fill="#FFFFFF" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" d="M157.868,485.326
      c0,1.98-0.401,3.867-1.127,5.583c-0.726,1.716-1.776,3.262-3.074,4.56s-2.843,2.348-4.56,3.074
      c-1.716,0.726-3.603,1.127-5.583,1.127s-3.867-0.401-5.583-1.127c-1.716-0.727-3.262-1.776-4.56-3.074s-2.348-2.844-3.074-4.56
      s-1.127-3.603-1.127-5.583s0.401-3.867,1.127-5.583s1.776-3.262,3.074-4.56s2.843-2.348,4.56-3.074
      c1.716-0.726,3.603-1.127,5.583-1.127s3.867,0.401,5.583,1.127c1.716,0.727,3.262,1.776,4.56,3.074s2.348,2.844,3.074,4.56
      C157.466,481.459,157.868,483.346,157.868,485.326z"/>
    <line fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" x1="123.633" y1="332.021" x2="105.21" y2="167.916"/>
    <line fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" x1="163.415" y1="332.021" x2="181.837" y2="167.916"/>
    <ellipse fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="111.806" cy="168.604" rx="6.596" ry="6.93"/>
    <ellipse fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="127.665" cy="168.604" rx="6.596" ry="6.93"/>
    <ellipse fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="143.524" cy="168.604" rx="6.596" ry="6.93"/>
    <ellipse fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="159.383" cy="168.604" rx="6.596" ry="6.93"/>
    <ellipse fill="none" stroke="#000000" strokeWidth="10" strokeMiterlimit="10" cx="175.241" cy="168.604" rx="6.596" ry="6.93"/>
    </svg>
  )
}
