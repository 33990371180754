import React, { Component } from 'react';
import { Controlled as CodeMirror} from 'react-codemirror2';
import { Button } from 'optimizely-oui';
import { events } from '../constants/analytics';
import { getText } from '../constants/copy';
import LightBulb from './lightbulb';

const INITIAL_STATE = [
 { name: 'Bob',     id: 0.2, },
 { name: 'Don',     id: 0.4, },
 { name: 'Felicia', id: 0.6, },
 { name: 'Charlie', id: 0.3, },
 { name: 'Eli',     id: 0.5, },
 { name: 'Ian',     id: 0.9, },
 { name: 'Alice',   id: 0.1, },
 { name: 'George',  id: 0.7, },
 { name: 'Hillary', id: 0.8, },
];

export default class UserExperiences extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: INITIAL_STATE,
      results: false,
    };
  }

  startRun(event) {
    // Detect if it was called by a click
    if (event) {
      window.analytics.track(events.APP_CODE_RERUN)
    }
    this.props.onStartRun();
    this.runApp();
  }

  componentDidMount() {
    this.props.onRef(this)
  }
  componentWillUnmount() {
    this.props.onRef(undefined)
  }

  async runApp() {
    let clear = () => {
      let clearPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          this.setState({ users: INITIAL_STATE, results: false })
          resolve()
        }, 0)
      })
    }
    await clear();

    for (let user of this.state.users) {
      let run = () => {
        let resultPromise = new Promise((resolve, reject) => {
          setTimeout(() => {
            this.runForUser(user.id)
            resolve()
          }, 150)
        })
        return resultPromise;
      }
      await run();
    }

    this.setState({
      results: true
    })

    this.props.onRunApp();
  }

  runForUser(id) {
    let featureEnabled = !!this.props.featureEnabled;
    let rollout = this.props.rollout / 100;
    let featureKey = this.props.featureKey
      ? `${this.props.featureKey} `
      : ``;

    let featureOn = (
      <span>
        {`got the ${featureKey}feature`}
      </span>
    )

    let featureOff = (
      <span>
        did <strong>NOT</strong> get {`the ${featureKey}feature`}
      </span>
    )

    let users = this.state.users.map((user) => {
      if (user.id === id) {
        let featureOnForUser = featureEnabled && user.id < rollout
        return {
          ...user,
          experience: featureOnForUser ? featureOn : featureOff,
          isOn: featureOnForUser
        }
      } else {
        return { ...user }
      }
    });
    this.setState({users})
  }

  render() {
    let runRows = this.state.users.map((user) => {
      return (
        <li key={user.id} style={styles.row}>{LightBulb(user.isOn)} <span style={styles.name}>{user.name}</span><span style={styles.text}> {user.experience}</span></li>
      )
    })

    let resultsStyles = this.state.results
      ? { opacity: 1.0 }
      : { opacity: 0.0 }

    let perc = `${this.props.rollout}% of users`;
    if (perc === '100% of users') perc = 'Everyone'
    if (!this.props.featureEnabled || perc === '0% of users') perc = 'Nobody'

    return (
      <div className="flex flex--column" style={styles.container}>
        {/* TODO (asa): Modal vs text Results?
        <div className="fade" style={{...styles.modalContainer,...resultsStyles}}>
          <div style={styles.modalContent}>
            <h5>{perc}</h5>
            <p>Got the feature</p>
          </div>
        </div>
        */}
        <div style={styles.runAppContainer}>
          <div style={styles.runAppButton}>
            { this.props.tooltip }
            <Button
              style={'highlight'}
              onClick={this.startRun.bind(this)}
            >
              <div style={styles.downloadButtonText}>
                <div> { getText('run-again') }</div>
                  <svg style={{'paddingLeft': '10px'}} width='30px' className="svg-inline--fa fa-play-circle fa-w-16" viewBox="0 0 512 512">
                    <path fill="currentColor" d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm115.7 272l-176 101c-15.8 8.8-35.7-2.5-35.7-21V152c0-18.4 19.8-29.8 35.7-21l176 107c16.4 9.2 16.4 32.9 0 42z"></path>
                  </svg>
              </div>
            </Button>
          </div>
        </div>
        <ul>
          { runRows }
          <li className="fade" style={{...styles.textResults,...resultsStyles}}><span><strong>{perc}</strong> got the feature!</span></li>
        </ul>
      </div>
    )
  }
}

const styles = {
  text: {
    paddingLeft: '10px',
  },
  name: {
    paddingLeft: '10px',
  },
  container: {
    width:'506px',
    height: '280px',
  },
  yourAppHeader: {
    font: '12px Monospace',
    backgroundColor: '#FFBF46',
    borderRadius: '5px 5px 0px 0px'
  },
  runAppContainer: {
    position: 'absolute',
    zIndex: 5,
    top: '265px',
    width: '506px',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  runAppButton: {
  },
  downloadButtonText: {
    display: 'flex',
    alignItems: 'center',
  },
  row: {
    font: '12px Monospace',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'monospace',
  },
  modalContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width:'506px',
    height: '180px',
  },
  modalContent: {
    width: '135px',
    position: 'absolute',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    border: 'solid 1px #AAAAAA',
    borderRadius: '5px',
    backgroundColor: 'white',
  },
  textResults: {
    marginTop: '3px',
    marginLeft: '20px',
    font: '12px Monospace',
    width: '100%',
  }
}
