import { getText } from './copy';

export default [
  {
    title: getText('step-1-title'),
    text: getText('step-1-text'),
    left: 0,
    top: 0,
  },
  {
    title: getText('step-2-title'),
    text: getText('step-2-text'),
    left: -630,
    top: 0,
  },
  {
    title: getText('step-3-title'),
    text: getText('step-3-text'),
    left: -1150,
    top: 0,
  },
  {
    title: getText('step-4-title'),
    text: getText('step-4-text'),
    left: -2200,
    top: 0,
  }
]
