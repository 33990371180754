const TRANSLATIONS = {
  'step-1-title': {
    'en': 'Create a Feature',
    'de': 'Feature erstellen',
  },
  'step-1-text': {
    'en': 'Save changes in the Optimizely UI and watch as those changes propagate to the datafile. The datafile includes all the information about your feature flags.',
    'de': 'Speichern Sie Änderungen im User Interface von Optimizely und beobachten Sie wie die Änderungen im Datafile übertragen werden. Die Datafile enthält sämtliche Informationen zu Ihren Feature Flags.',
  },
  'step-2-title': {
    'en': 'Implement your Feature',
    'de': 'Feature implementieren',
  },
  'step-2-text': {
    'en': 'Create an Optimizely instance with the datafile and use isFeatureEnabled to see if the feature is enabled for a user.',
    'de': 'Erstellen Sie eine Optimizely-Instanz mit der Datafile und benutzen Sie „isFeatureEnabled“ um zu sehen ob das Feature für den Nutzer aktiviert ist.',
  },
  'step-3-title': {
    'en': 'Run your App',
    'de': 'App ausführen',
  },
  'step-3-text': {
    'en': 'Run your app for different users and see which users are exposed to the feature based on your percentile rules.',
    'de': 'Führen Sie die App für unterschiedliche Nutzer aus, um zu sehen, welchen Nutzern das Feature nach Ihren Perzentil-Vorgaben angezeigt wird.',
  },
  'step-4-title': {
    'en': 'Congrats!',
    'de': 'Glückwunsch!',
  },
  'step-4-text': {
    'en': 'You understand the basics of Optimizely Rollouts. You can now release with more confidence by rolling out and rolling back features without code deploys.',
    'de': 'Sie kennen jetzt die Grundlagen von Optimizely Rollouts. Ab sofort kann jeder Ihrer Releases mehr Gewissheit auf Erfolg haben, indem Sie Rollouts und Rollbacks von Features ohne aufwändige Code-Anpassungen ausführen können.',
  },
  'optly-application': {
    'en': 'Optimizely Application',
    'de': 'Optimizely-Applikation',
  },
  'your-datafile': {
    'en': 'Your Datafile',
    'de': 'Ihr Datenfile',
  },
  'name-your-feature': {
    'en': 'Name your feature',
    'de': 'Benennen Sie Ihr Feature',
  },
  'turn-on-your-feature': {
    'en': 'Turn on your feature',
    'de': 'Schalten Sie ihr Feature ein',
  },
  'rollout-your-feature': {
    'en': 'Rollout your feature',
    'de': 'Rollout Ihres Features',
  },
  'save-your-changes': {
    'en': 'Save your changes',
    'de': 'Speichern Sie Ihre Änderungen',
  },
  'ready-to-implement': {
    'en': 'Ready to implement?',
    'de': 'Bereit für die Implementierung?',
  },
  'run-the-code': {
    'en': 'Run the code',
    'de': 'Code ausführen',
  },
  'see-whats-next': {
    'en': `See what's next`,
    'de': 'Sehen, was als nächstes kommt',
  },
  'try-it-out': {
    'en': 'Try it out',
    'de': 'Jetzt testen',
  },
  'see-docs': {
    'en': 'See Docs',
    'de': 'Dokumentationen anzeigen',
  },
  'create-account': {
    'en': 'Create an Account',
    'de': 'Konto erstellen',
  },
  'reset-demo': {
    'en': 'Reset Demo',
    'de': 'Demo zurücksetzen',
  },
  'your-app-code': {
    'en': 'Your App Code',
    'de': 'Ihr App-Code',
  },
  'your-apps-users': {
    'en': `Your App's Users`,
    'de': 'Ihre App-Nutzer',
  },
  'run-again': {
    'en': 'Run Again',
    'de': 'Nochmal ausführen',
  },
  'next': {
    'en': 'Next',
    'de': 'Weiter',
  },
  'back': {
    'en': 'Back',
    'de': 'Zurück',
  },
  'save': {
    'en': 'Save',
    'de': 'Speichern',
  },
}

const TOOLTIP_OFFSETS = {
  'name-your-feature': {
    'en': {
      'x': '179px',
      'y': '25px',
    },
    'de': {
      'x': '179px',
      'y': '25px',
    },
  },
  'turn-on-your-feature': {
    'en': {
      'x': '75px',
      'y': '-19px',
    },
    'de': {
      'x': '75px',
      'y': '-19px',
    },
  },
  'rollout-your-feature': {
    'en': {
      'x': '317px',
      'y': '-53px',
    },
    'de': {
      'x': '317px',
      'y': '-53px',
    },
  },
  'save-your-changes': {
    'en': {
      'x': '15px',
      'y': '0px',
    },
    'de': {
      'x': '15px',
      'y': '0px',
    },
  },
  'ready-to-implement': {
    'en': {
      'x': '-97px',
      'y': '-55px',
    },
    'de': {
      'x': '-157px',
      'y': '-55px',
    },
  },
  'run-the-code': {
    'en': {
      'x': '-49px',
      'y': '-55px',
    },
    'de': {
      'x': '-52px',
      'y': '-55px',
    },
  },
  'see-whats-next': {
    'en': {
      'x': '-64px',
      'y': '-55px',
    },
    'de': {
      'x': '-152px',
      'y': '-55px',
    },
  },
  'try-it-out': {
    'en': {
      'x': '157px',
      'y': '0px',
    },
    'de': {
      'x': '140px',
      'y': '0px',
    },
  },
}

const TEXT_KEYS = Object.keys(TRANSLATIONS);

function getTranslatedText(id, language, debug) {
  var translation = '';
  if (TEXT_KEYS.includes(id)) {
    if (TRANSLATIONS[id][language]) {
      return String(TRANSLATIONS[id][language])
    } else {
      console.warn(`[Rollouts Demo] text for ${id} has no translation for ${language}`)
      return String(TRANSLATIONS[id]['en'])
    }
  }
  console.warn(`[Rollouts Demo] no text exists for ${id}`)
  return String(undefined)
}

function getOffset(id, direction) {
  var urlParams = new URLSearchParams(window.location.search);
  var language = urlParams.get('language') || 'en'
  var debug = urlParams.get('debug') || false
  var offsets;
  try {
    offsets = TOOLTIP_OFFSETS[id][language];
  } catch {
    console.warn(`[Rollouts Demo] text for ${id} has no offsets for ${language}`)
  }
  return offsets[direction] || 0;
}

export function getText(id) {
  var urlParams = new URLSearchParams(window.location.search);
  var language = urlParams.get('language') || 'en'
  var debug = urlParams.get('debug') || false
  return getTranslatedText(id, language, debug);
}

export function getOffsetX(id) {
  return getOffset(id, 'x');
}

export function getOffsetY(id) {
  return getOffset(id, 'y');
}
