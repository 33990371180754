import React from 'react';
import { DropTarget } from 'react-dnd';

const datafileTarget = {
  drop(props) {
    props.onDrop();
  }
};

function collect(connect, monitor) {
  return {
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver()
  };
}

function DroppableDatafile({ x, y, connectDropTarget, isOver, children }) {

  return connectDropTarget(
    <div style={{
      position: 'relative',
      width: '100%',
      height: '100%',
      width: '70px',
      height: '20px',
      borderRadius: '5px',
      border: '1px dashed #FFFFFF',
      backgroundColor:'#233238'
    }}>
      {isOver &&
        <div style={{
          position: 'absolute',
          top: 0,
          left: 0,
          height: '100%',
          width: '100%',
          zIndex: 1,
          backgroundColor: '#FFBF46',
        }} />
      }
    </div>
  );
}

export default DropTarget('datafile', datafileTarget, collect)(DroppableDatafile);
